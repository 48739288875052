
/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/


/**
 * AUTO-GENERATED FILE. DO NOT MODIFY.
 */

/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/
import { __extends } from "tslib";
import * as graphic from '../../util/graphic.js';
import { setStatesStylesFromModel, toggleHoverEmphasis } from '../../util/states.js';
import * as zrUtil from 'zrender/lib/core/util.js';
import * as symbolUtil from '../../util/symbol.js';
import ChartView from '../../view/Chart.js';
import { setLabelStyle, getLabelStatesModels } from '../../label/labelStyle.js';
import ZRImage from 'zrender/lib/graphic/Image.js';
import { saveOldStyle } from '../../animation/basicTrasition.js';

var RadarView =
/** @class */
function (_super) {
  __extends(RadarView, _super);

  function RadarView() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.type = RadarView.type;
    return _this;
  }

  RadarView.prototype.render = function (seriesModel, ecModel, api) {
    var polar = seriesModel.coordinateSystem;
    var group = this.group;
    var data = seriesModel.getData();
    var oldData = this._data;

    function createSymbol(data, idx) {
      var symbolType = data.getItemVisual(idx, 'symbol') || 'circle';

      if (symbolType === 'none') {
        return;
      }

      var symbolSize = symbolUtil.normalizeSymbolSize(data.getItemVisual(idx, 'symbolSize')); // chen add start

      if (symbolType === "text") {
        return null;
      } // chen add end


      var symbolPath = symbolUtil.createSymbol(symbolType, -1, -1, 2, 2);
      var symbolRotate = data.getItemVisual(idx, 'symbolRotate') || 0;
      symbolPath.attr({
        style: {
          strokeNoScale: true
        },
        z2: 100,
        scaleX: symbolSize[0] / 2,
        scaleY: symbolSize[1] / 2,
        rotation: symbolRotate * Math.PI / 180 || 0
      });
      return symbolPath;
    }

    function updateSymbols(oldPoints, newPoints, symbolGroup, data, idx, isInit, // chen add start
    descriptionLineGroup // chen add end
    ) {
      var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m; // chen add start


      descriptionLineGroup === null || descriptionLineGroup === void 0 ? void 0 : descriptionLineGroup.removeAll();
      var itemModel = data.getItemModel(idx);
      var optionData = itemModel.option; // chen add end
      // Simply rerender all

      symbolGroup.removeAll();

      for (var i = 0; i < newPoints.length - 1; i++) {
        var symbolPath = createSymbol(data, idx);

        if (symbolPath) {
          symbolPath.__dimIdx = i;

          if (oldPoints[i]) {
            symbolPath.setPosition(oldPoints[i]);
            graphic[isInit ? 'initProps' : 'updateProps'](symbolPath, {
              x: newPoints[i][0],
              y: newPoints[i][1]
            }, seriesModel, idx);
          } else {
            symbolPath.setPosition(newPoints[i]);
          }

          symbolGroup.add(symbolPath);
        } // chen add start
        else {
            // new text symbol
            if (optionData.symbolLetter && optionData.symbolLetter.length > i) {
              var textData = optionData.symbolLetter[i];
              var _o = optionData.symbolOffsetPosition[i],
                  positionX = _o[0],
                  positionY = _o[1];
              var symbolText = new graphic.Text();
              symbolText.attr({
                style: {
                  opacity: 0,
                  text: textData,
                  fill: (_a = optionData.symbolFont) === null || _a === void 0 ? void 0 : _a.color,
                  fontSize: (_b = optionData.symbolFont) === null || _b === void 0 ? void 0 : _b.fontSize,
                  fontFamily: (_c = optionData.symbolFont) === null || _c === void 0 ? void 0 : _c.fontFamily,
                  fontStyle: (_d = optionData.symbolFont) === null || _d === void 0 ? void 0 : _d.fontStyle,
                  fontWeight: (_e = optionData.symbolFont) === null || _e === void 0 ? void 0 : _e.fontWeight
                }
              });
              var newPointPosition = [newPoints[i][0] + positionX, newPoints[i][1] + positionY];
              symbolText.setPosition(newPointPosition); // set animation option

              var externalOpt = {
                duration: (_f = optionData.symbolFont) === null || _f === void 0 ? void 0 : _f.duration,
                delay: (_g = optionData.symbolFont) === null || _g === void 0 ? void 0 : _g.delay,
                ease: (_h = optionData.symbolFont) === null || _h === void 0 ? void 0 : _h.ease
              }; // show current

              if (isInit && optionData.isCurrent) {
                graphic[isInit ? 'initProps' : 'updateProps'](symbolText, {
                  style: {
                    opacity: 1
                  }
                }, seriesModel, idx, null, null, externalOpt);
              }

              symbolGroup.add(symbolText);
            }
          } // chen add end

      } // chen add start


      if (!optionData.descriptionLine) {
        return;
      }

      var descriptionLine = optionData.descriptionLine; // get point

      var leftPoint = [polar.cx - polar.r, polar.cy];
      var bottomPoint = [polar.cx, polar.cy + polar.r];
      var rightPoint = [polar.cx + polar.r, polar.cy];
      var lineWidth = 70;
      var movePosition = 50;

      if (descriptionLine.position === 'left') {
        var middlePoint = [(leftPoint[0] + bottomPoint[0]) / 2 - movePosition, (leftPoint[1] + bottomPoint[1]) / 2 + movePosition];
        var leftLinePoint = [middlePoint[0] - lineWidth, middlePoint[1]];
        var startPoint = [(newPoints[1][0] + newPoints[2][0]) / 2, (newPoints[1][1] + newPoints[2][1]) / 2];
        var descriptionLine_1 = new graphic.Polyline();
        descriptionLineGroup === null || descriptionLineGroup === void 0 ? void 0 : descriptionLineGroup.add(descriptionLine_1);
        descriptionLine_1.shape.points = [startPoint, middlePoint, leftLinePoint];
        descriptionLine_1.useStyle({
          fill: "none",
          stroke: "#8A8A8A",
          lineDash: "solid",
          lineJoin: "round",
          lineWidth: 1
        }); // add string

        var description = new graphic.Text();
        descriptionLineGroup === null || descriptionLineGroup === void 0 ? void 0 : descriptionLineGroup.add(description);
        description.attr({
          style: {
            text: (_j = optionData.descriptionLine) === null || _j === void 0 ? void 0 : _j.description.toString(),
            fill: "#334250",
            fontSize: 12,
            fontFamily: "Noto Sans JP",
            fontStyle: "normal",
            fontWeight: 500
          }
        });
        description.setPosition([leftLinePoint[0], leftLinePoint[1] + 7]); // add score

        var descriptionScore = new graphic.Text();
        descriptionLineGroup === null || descriptionLineGroup === void 0 ? void 0 : descriptionLineGroup.add(descriptionScore);
        descriptionScore.attr({
          style: {
            text: (_k = optionData.descriptionLine) === null || _k === void 0 ? void 0 : _k.score.toString(),
            fill: "#334250",
            fontSize: 30,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 700
          }
        });
        descriptionScore.setPosition([leftLinePoint[0], leftLinePoint[1] - 40]);
      } else if (descriptionLine.position === 'right') {
        var middlePoint = [(rightPoint[0] + bottomPoint[0]) / 2 + movePosition, (rightPoint[1] + bottomPoint[1]) / 2 + movePosition];
        var rightLinePoint = [middlePoint[0] + lineWidth, middlePoint[1]];
        var startPoint = [(newPoints[3][0] + newPoints[2][0]) / 2, (newPoints[3][1] + newPoints[2][1]) / 2];
        var descriptionLine_2 = new graphic.Polyline();
        descriptionLineGroup === null || descriptionLineGroup === void 0 ? void 0 : descriptionLineGroup.add(descriptionLine_2);
        descriptionLine_2.shape.points = [startPoint, middlePoint, rightLinePoint];
        descriptionLine_2.useStyle({
          fill: "none",
          stroke: "#8A8A8A",
          lineDash: "solid",
          lineJoin: "round",
          lineWidth: 1
        }); // add string

        var description = new graphic.Text();
        descriptionLineGroup === null || descriptionLineGroup === void 0 ? void 0 : descriptionLineGroup.add(description);
        description.attr({
          style: {
            text: (_l = optionData.descriptionLine) === null || _l === void 0 ? void 0 : _l.description.toString(),
            fill: "#334250",
            fontSize: 12,
            fontFamily: "Noto Sans JP",
            fontStyle: "normal",
            fontWeight: 500
          }
        });
        description.setPosition([middlePoint[0], middlePoint[1] + 7]); // add score

        var descriptionScore = new graphic.Text();
        descriptionLineGroup === null || descriptionLineGroup === void 0 ? void 0 : descriptionLineGroup.add(descriptionScore);
        descriptionScore.attr({
          style: {
            text: (_m = optionData.descriptionLine) === null || _m === void 0 ? void 0 : _m.score.toString(),
            fill: "#334250",
            fontSize: 30,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 700,
            align: 'right'
          }
        });
        descriptionScore.setPosition([middlePoint[0] + lineWidth, middlePoint[1] - 40]);
      } // chen add end

    }

    function getInitialPoints(points) {
      return zrUtil.map(points, function (pt) {
        return [polar.cx, polar.cy];
      });
    }

    data.diff(oldData).add(function (idx) {
      var points = data.getItemLayout(idx);

      if (!points) {
        return;
      }

      var polygon = new graphic.Polygon();
      var polyline = new graphic.Polyline();
      var target = {
        shape: {
          points: points
        }
      };
      polygon.shape.points = getInitialPoints(points);
      polyline.shape.points = getInitialPoints(points);
      graphic.initProps(polygon, target, seriesModel, idx);
      graphic.initProps(polyline, target, seriesModel, idx);
      var itemGroup = new graphic.Group();
      var symbolGroup = new graphic.Group();
      itemGroup.add(polyline);
      itemGroup.add(polygon);
      itemGroup.add(symbolGroup); // chen add start

      var descriptionLineGroup = new graphic.Group();
      itemGroup.add(descriptionLineGroup); // chen add end

      updateSymbols(polyline.shape.points, points, symbolGroup, data, idx, true // chen add start
      , descriptionLineGroup // chen add end
      );
      data.setItemGraphicEl(idx, itemGroup);
    }).update(function (newIdx, oldIdx) {
      var itemGroup = oldData.getItemGraphicEl(oldIdx);
      var polyline = itemGroup.childAt(0);
      var polygon = itemGroup.childAt(1);
      var symbolGroup = itemGroup.childAt(2); // chen add start

      var descriptionLineGroup = itemGroup.childAt(3); // chen add end

      var target = {
        shape: {
          points: data.getItemLayout(newIdx)
        }
      };

      if (!target.shape.points) {
        return;
      }

      updateSymbols(polyline.shape.points, target.shape.points, symbolGroup, data, newIdx, false // chen add start
      , descriptionLineGroup // chen add end
      );
      saveOldStyle(polygon);
      saveOldStyle(polyline);
      graphic.updateProps(polyline, target, seriesModel);
      graphic.updateProps(polygon, target, seriesModel);
      data.setItemGraphicEl(newIdx, itemGroup);
    }).remove(function (idx) {
      group.remove(oldData.getItemGraphicEl(idx));
    }).execute();
    data.eachItemGraphicEl(function (itemGroup, idx) {
      var itemModel = data.getItemModel(idx);
      var polyline = itemGroup.childAt(0);
      var polygon = itemGroup.childAt(1);
      var symbolGroup = itemGroup.childAt(2); // Radar uses the visual encoded from itemStyle.

      var itemStyle = data.getItemVisual(idx, 'style');
      var color = itemStyle.fill;
      group.add(itemGroup); // chen add start
      // const optionData = itemModel.option;
      // const lineColor = optionData.lineColor ?? color;
      // const lineOpacity = optionData.lineOpacity ?? 1;
      // chen add end

      polyline.useStyle(zrUtil.defaults(itemModel.getModel('lineStyle').getLineStyle(), {
        fill: 'none'
      }));
      setStatesStylesFromModel(polyline, itemModel, 'lineStyle');
      setStatesStylesFromModel(polygon, itemModel, 'areaStyle');
      var areaStyleModel = itemModel.getModel('areaStyle');
      var polygonIgnore = areaStyleModel.isEmpty() && areaStyleModel.parentModel.isEmpty();
      polygon.ignore = polygonIgnore;
      zrUtil.each(['emphasis', 'select', 'blur'], function (stateName) {
        var stateModel = itemModel.getModel([stateName, 'areaStyle']);
        var stateIgnore = stateModel.isEmpty() && stateModel.parentModel.isEmpty(); // Won't be ignore if normal state is not ignore.

        polygon.ensureState(stateName).ignore = stateIgnore && polygonIgnore;
      });
      polygon.useStyle(zrUtil.defaults(areaStyleModel.getAreaStyle(), {
        fill: color,
        opacity: 0.7,
        decal: itemStyle.decal
      }));
      var emphasisModel = itemModel.getModel('emphasis');
      var itemHoverStyle = emphasisModel.getModel('itemStyle').getItemStyle();
      symbolGroup.eachChild(function (symbolPath) {
        // chen add start
        // escape when text
        if (symbolPath instanceof graphic.Text) {
          return;
        } // chen add end


        if (symbolPath instanceof ZRImage) {
          var pathStyle = symbolPath.style;
          symbolPath.useStyle(zrUtil.extend({
            // TODO other properties like x, y ?
            image: pathStyle.image,
            x: pathStyle.x,
            y: pathStyle.y,
            width: pathStyle.width,
            height: pathStyle.height
          }, itemStyle));
        } else {
          symbolPath.useStyle(itemStyle);
          symbolPath.setColor(color);
          symbolPath.style.strokeNoScale = true;
        }

        var pathEmphasisState = symbolPath.ensureState('emphasis');
        pathEmphasisState.style = zrUtil.clone(itemHoverStyle);
        var defaultText = data.getStore().get(data.getDimensionIndex(symbolPath.__dimIdx), idx);
        (defaultText == null || isNaN(defaultText)) && (defaultText = '');
        setLabelStyle(symbolPath, getLabelStatesModels(itemModel), {
          labelFetcher: data.hostModel,
          labelDataIndex: idx,
          labelDimIndex: symbolPath.__dimIdx,
          defaultText: defaultText,
          inheritColor: color,
          defaultOpacity: itemStyle.opacity
        });
      });
      toggleHoverEmphasis(itemGroup, emphasisModel.get('focus'), emphasisModel.get('blurScope'), emphasisModel.get('disabled'));
    });
    this._data = data;
  };

  RadarView.prototype.remove = function () {
    this.group.removeAll();
    this._data = null;
  }; // chen add start


  RadarView.prototype.setGraphicDsp = function (showIdx, hideIdx) {
    var modelData = this._data.getItemGraphicEl(hideIdx);

    var polyline = modelData.childAt(0);
    var polygon = modelData.childAt(1);
    var symbolGroup = modelData.childAt(2);
    var z2Hide = 1;
    var zHideOpacity = 0.2;
    var z2Show = 10;
    var zShowOpacity = 0.6; // set animation option

    var externalOpt = {
      duration: 300,
      delay: 0
    };
    var updateFun = graphic['updateProps']; // update hide the image

    updateFun(polyline, {
      z2: z2Hide,
      style: {
        opacity: zHideOpacity
      }
    }, this._data.hostModel, hideIdx, null, null, externalOpt);
    updateFun(polygon, {
      z2: z2Hide,
      style: {
        opacity: zHideOpacity
      }
    }, this._data.hostModel, hideIdx, null, null, externalOpt); // text get new opacity

    for (var i = 0; i < symbolGroup.childCount(); i++) {
      updateFun(symbolGroup.childAt(i), {
        z2: z2Hide,
        style: {
          opacity: 0
        }
      }, this._data.hostModel, hideIdx, null, null, externalOpt);
    } // set animation option


    var externalOptShow = {
      duration: 300,
      delay: 150
    };

    var modelDataShow = this._data.getItemGraphicEl(showIdx);

    var polylineShow = modelDataShow.childAt(0);
    var polygonShow = modelDataShow.childAt(1);
    var symbolGroupShow = modelDataShow.childAt(2); // update hide the image

    updateFun(polylineShow, {
      z2: z2Show,
      style: {
        opacity: zShowOpacity
      }
    }, this._data.hostModel, hideIdx, null, null, externalOptShow);
    updateFun(polygonShow, {
      z2: z2Show,
      style: {
        opacity: zShowOpacity
      }
    }, this._data.hostModel, hideIdx, null, null, externalOptShow); // text get new opacity

    for (var i = 0; i < symbolGroupShow.childCount(); i++) {
      updateFun(symbolGroupShow.childAt(i), {
        z2: z2Show,
        style: {
          opacity: 1
        }
      }, this._data.hostModel, hideIdx, null, null, externalOptShow);
    }
  };

  RadarView.type = 'radar';
  return RadarView;
}(ChartView);

export default RadarView;